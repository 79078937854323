import React, { useState } from "react"
import CalltoActionButton from "../CalltoActionButton/CalltoActionButton"
import AnalyticsContextHOC from "../AnalyticsContext/AnalyticsContextHOC.js"
import { hasMultipleLocations } from "../helpers"
import "./Header.css"

const Header = props => {
  const mobile = window.innerWidth < 800
  const [expanded, setExpanded] = useState(false)

  const handleLinkClick = link => {
    setExpanded(false)
    props.setRoute(link)
    props.analytics.send(
      props.rest.name,
      link,
      "Header Link")
  }

  const handleSocialClick = button => {
    props.analytics.send(
      props.rest.name,
      button,
      "Header Link"
    )
    if (button === "facebook") window.open(props.rest.facebook_link, "_blank")
    if (button === "instagram") window.open(props.rest.instagram_link, "_blank")
    if (button === "twitter") window.open(props.rest.twitter_link, "_blank")
  }

  const handleOrderClick = () => {
    props.analytics.send(
      props.rest.name,
      "Order Online",
      "Header Link"
    )
  }

  const renderHeaderLinks = () => {
    let linkStyle = mobile ? "HeaderLinkMobile" : "HeaderLink"
    let locationLink =
      props.rest.locations.length === 1 ? "Address + Hours" : "Locations"
    let links = [
      <button
        role="link"
        onClick={() => handleLinkClick("location")}
        key="location"
        className={linkStyle}
        to="/location"
      >
        {locationLink}
      </button>
    ]
    if (Object.keys(props.rest.gallery).length > 0) {
      links.push(
        <button
          role="link"
          onClick={() => handleLinkClick("gallery")}
          key="gallery"
          className={linkStyle}
          to="/gallery"
        >
          Gallery
        </button>
      )
    }
    if (props.rest.reviews.length > 0) {
      links.push(
        <button
          role="link"
          onClick={() => handleLinkClick("reviews")}
          key="reviews"
          className={linkStyle}
          to="/reviews"
        >
          Reviews
        </button>
      )
    }
    if (props.rest.sections) {
      props.rest.sections.forEach((section, i) => {
        const sectionName = section.header.replace(/\s+/g, "-").toLowerCase()
        links.push(
          <button
            role="link"
            onClick={() => handleLinkClick(sectionName)}
            key={sectionName}
            className={linkStyle}
            to={`/${sectionName}`}>
            {section.header}
          </button>
        )
      })
    }

    // only show menu if we've set the header for it
    if (props.rest.menu_header && props.rest.menu_header != '') {
      links.push(
        <button
          role="link"
          onClick={() => handleLinkClick("menu")}
          key="menu"
          className={linkStyle}
          to="/menu">
          {props.rest.menu_header} 
        </button>
      )
    }

    // support for external links (reservations, etc)
    if (props.rest.external_links) {
      props.rest.external_links.forEach((ext_link, i) => {
        links.push(
          <a
            href={ext_link.url}
            key={i}
            target="_blank"
            rel="noopener noreferrer"
            className={linkStyle}
          >
            {ext_link.name}
          </a>
        )
      })
    }

    if (props.rest.contact) {
      links.push(
        <button
          role="link"
          onClick={() => handleLinkClick("contact")}
          key="contact"
          className={linkStyle}
          to="/contact"
        >
          Contact Us
        </button>
      )
    }

    if (hasMultipleLocations(props.rest)) {
      links.push(
        <CalltoActionButton
          as="button"
          onClick={() => props.scrollToSection("location")}
          params={props.rest.utms}
          className="HeaderButton"
          key={-6}
          style={{ background: props.rest.hex_color_secondary }}
          title="ORDER NOW"
        />
      )
    } else {
      links.push(
        <CalltoActionButton
          onClick={() => handleOrderClick("location")}
          href={props.rest.ordering_url}
          params={props.rest.utms}
          key={-7}
          className="HeaderButton"
          style={{ background: props.rest.hex_color_secondary }}
          title="ORDER NOW"
        />
      )
    }
    return links
  }

  const toggleMenu = () => {
    setExpanded(!expanded)
  }

  const renderMobile = () => {
    if (expanded) {
      return (
        <div
          className="HeaderContainer HeaderContainerMobileExpanded"
          style={{ background: "rgba(0,0,0,.7)" }}
        >
          <div className="HeaderLinksMobileContainer">
            <img className="HeaderImg" src={props.rest.logo} alt="logo" />
            <img
              className="HeaderMenu"
              src="./icons/menu.png"
              alt="menu"
              onClick={toggleMenu}
            />
          </div>
          <div className="HeaderLinksMobile">{renderHeaderLinks()}</div>
        </div>
      )
    }
    return (
      <div className="HeaderContainer">
        <img className="HeaderImg" src={props.rest.logo} alt="logo" />
        {props.rest.facebook_link && (
          <img
            className="HeaderSocialImg"
            style={{ opacity: 1 }}
            src="./icons/facebook.png"
            onClick={() => handleSocialClick("facebook")}
            alt="facebook"
          />
        )}
        {props.rest.instagram_link && (
          <img
            className="HeaderSocialImg"
            style={{ opacity: 1 }}
            src="./icons/instagram.png"
            onClick={() => handleSocialClick("instagram")}
            alt="facebook"
          />
        )}
        {props.rest.twitter_link && (
          <img
            className="HeaderSocialImg"
            style={{ opacity: 1 }}
            src="./icons/twitter.png"
            onClick={() => handleSocialClick("twitter")}
            alt="twitter"
          />
        )}
        <img
          className="HeaderMenu"
          src="./icons/menu.png"
          alt="menu"
          onClick={toggleMenu}
        />
      </div>
    )
  }

  const renderDesktop = () => {
    return (
      <div className="HeaderContainer">
        <img className="HeaderImg" src={props.rest.logo} alt="logo" />
        {props.rest.facebook_link && (
          <img
            className="HeaderSocialImg"
            src="./icons/facebook.png"
            onClick={() => handleSocialClick("facebook")}
            alt="facebook"
          />
        )}
        {props.rest.instagram_link && (
          <img
            className="HeaderSocialImg"
            src="./icons/instagram.png"
            onClick={() => handleSocialClick("instagram")}
            alt="facebook"
          />
        )}
        {props.rest.twitter_link && (
          <img
            className="HeaderSocialImg"
            src="./icons/twitter.png"
            onClick={() => handleSocialClick("twitter")}
            alt="twitter"
          />
        )}
        <div className="HeaderLinks">{renderHeaderLinks()}</div>
      </div>
    )
  }

  if (mobile) {
    return renderMobile()
  } else {
    return renderDesktop()
  }
}

export default AnalyticsContextHOC(Header)
